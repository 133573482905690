/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // load flexslider for home and work
        $('.page-header.flexslider').flexslider({
          controlNav: true,
          directionNav: false,
          slideshowSpeed: 4500,
          animation: 'fade',
          pauseOnHover: true,
          prevText: '',
          nextText: '',
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // common elements

        // add facetwp js stuff
        $(document).on('facetwp-loaded', function() {
          /* on sector landing pages, display the sector and sub-sector filter options for that sector */
          // get sector name; add active class to sector filter that matches name
          var body_classes = $('body').attr('class').split(' ');
          var sector_class = body_classes[2].substring(5);
          $('.facetwp-checkbox').each(function() {
            if ($(this).attr('data-value') === sector_class) {
              $(this).addClass('active');
            }
          });
          $('a.facetwp-page, .facetwp-checkbox').click(function() {
            $('body').animate({ scrollTop: 0 }, 500);
          });
          if (FWP.loaded) {
            $('body').animate({ scrollTop: 0 }, 500);
            // if facets chosen then add activate class; otherwise remove it
            if (checkForActiveFacets(FWP.facets)) {
              $('body').addClass('facetwp-active');
            }
            else {
              $('body').removeClass('facetwp-active');
            }
          }

        });

        function checkForActiveFacets(facets) {
          //console.log(facets);
          var active = false;
          $.each(facets, function(key, val) {
            if (val.length > 0) {
              active = true;
            }
          });
          return active;
        }

        /* Navbar and search display and functionality */
        // create navbar and header variables and set their values
        var navbar_toggler = $('.navbar-toggler');
        var search_toggler = $('.search-toggler');
        var nav_primary = $('.nav-primary');
        var navbar = $('#navbar');
        var search_form = $('.search-form');
        var header_banner = $('header.banner');
        var page_header = $('.content .page-header');
        var header_image_background = $('.content .page-header .header-image-background');
        var hbk_height = 0;
        if ($(header_image_background).length) {
          hbk_height = $(header_image_background).height() - $(header_banner).height();
        }
        else {
          hbk_height = $(page_header).height() - $(header_banner).height();
        }

        // show/hide nav
        $(navbar_toggler).click(function() {
          if (!$('.nav-primary').hasClass('active')) {
            $(nav_primary).addClass('active');
          }
          if ($(navbar).hasClass('show')) {
            $(nav_primary).removeClass('active');
            $(navbar).removeClass('show');
          }
          else {
            $(navbar).addClass('show');
            $(navbar).addClass('show');
            $(search_form).removeClass('show');
          }
          /*
          if ($(this).hasClass('navbar-toggler')) {
          }
          if ($(this).hasClass('search-toggler')) {

          }
          */
        });
        // show/hide search
        $(search_toggler).click(function() {
          if (!$(nav_primary).hasClass('active')) {
            $(nav_primary).addClass('active');
          }
          if ($(search_form).hasClass('show')) {
            $(nav_primary).removeClass('active');
            $(search_form).removeClass('show');
          }
          else {
            $(navbar).removeClass('show');
            $(search_form).addClass('show');
            $('.search-field').focus();
          }
        });

        // show/hide filter
        $('.filter-toggler').click(function() {
          if ($('.term-filter').hasClass('active')) {
            $('.term-filter').removeClass('active');
          }
          else {
            $('.term-filter').addClass('active');
          }
        });

        /* Grid section functions */
        // load Metafizzy Packery for grid
        var $grid = $('.grid.pack').packery({
          itemSelector: '.grid-item',
        });
        // load-more button function - when new posts have been added to the page then append items to Packery.
        $( '.load-more' ).on( 'click', function () {
          // New posts have been added to the page.
          var appended_items = $('.appended-items').find('.grid-item');
          $grid.append( appended_items ).packery( 'appended', appended_items );
          //console.log('thjis');
        } );

        $( document.body ).on( 'post-load', function () {
          // New posts have been added to the page.
          var appended_items = $('.appended-items').find('.grid-item');
          $grid.append( appended_items ).packery( 'appended', appended_items );
          //console.log('thjis');
        } );


        /* Smooth scroll function for footer arrow */
        $('a.footer-arrow').click(function(){
          //Animate
          var target = this.hash;
          var $target = $(target);

          $('html, body').stop().animate({
            'scrollTop': $target.offset().top - 100
          }, 900, 'swing', function () {
            window.location.hash = target;
            //console.log($(window).scrollTop());
          });
          return false;
        });

        // activate header background on scroll
        $(window).scroll(function() {
          var wpos = $(window).scrollTop();

          if (wpos > hbk_height) {
            if (!$(header_banner).hasClass('active')) {
              $(header_banner).addClass('active');
              //$(navbar).addClass('hide');
            }
          }
          else {
            if ($(header_banner).hasClass('active')) {
              $(header_banner).removeClass('active');
              //$(navbar).removeClass('hide');
            }
          }

        });

        /* Issuu modal popup functionality  with featherlight */
        var window_width = $(window).width() * .8;
        var window_height = $(window).height() * .8;
        $('.issuuembed').css({ width: window_width, height: window_height});
        $('.issuu-link')
          .attr('data-featherlight-iframe-width', window_width)
          .attr('data-featherlight-iframe-height', window_height);

        /* touchstart action function for preventing menu parent link on first click so that submenu can be opened first */
        $('#navbar li.menu-item-has-children').on('touchstart', function(e) {
          if ($(this).hasClass('hover')) {
            return true;
          }
          else {
            $(this).addClass('hover');
            $(this).find('.sub-menu').show();
            e.preventDefault();
            //alert('clicked');
            return false;
          }
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // JavaScript to be fired on the about us pages

      }
    },
    'single_projects': {
      finalize: function() {
        // gallery function for opening Featherlight gallery
        $('a.gallery').featherlightGallery({
          previousIcon: '<',
          nextIcon: '>',
          galleryFadeIn: 300,
          openSpeed: 300,
          afterOpen: function(event){
            console.log('i opened it'); // this contains all related elements
            //$('.featherlight-next').attr('title','xx');
          }
        });

      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
